<template>
  <vue-good-table
    class="mt-3"
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="directionIsRTL"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage,
    }"
    style-class="vgt-table striped"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: Actions -->
      <div
        v-if="props.column.field === 'actions'"
        cols="12"
        md="2"
        class="d-flex vgt-center-align"
      >
        <b-button
          variant="outline-none"
          class="nav pr-0 pl-0"
        >
          <b-nav-item-dropdown class="btn-icon">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="20"
              />
            </template>

            <!-- <b-dropdown-item
              link-class="d-flex align-items-center"
              @click="editTraining(props.row)"
            >
              <feather-icon
                size="16"
                icon="EditIcon"
                class="mr-50"
              />
              <span>Edit</span>
            </b-dropdown-item> -->

            <b-dropdown-item
              link-class="d-flex align-items-center"
              @click="launchProgramTraining(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="ChromeIcon"
                class="mr-50"
              />
              <span>Launch Training</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="isProgramTypeTraining"
              link-class="d-flex align-items-center"
              @click="launchProgramTrainingUsers(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="UserIcon"
                class="mr-50"
              />
              <span>View Participants</span>
            </b-dropdown-item>

            <b-dropdown-item
              link-class="d-flex align-items-center"
              @click="viewProgramTrainingLog(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="BarChart2Icon"
                class="mr-50"
              />
              <span>View Training Logs</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-button>
        <b-dropdown
          variant="link"
          no-caret
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <b-button
              variant="outline-none"
              class="pl-0 pr-0"
            >
              <feather-icon
                icon="DownloadIcon"
                size="20"
              />
            </b-button>
          </template>

          <b-dropdown-item @click="onDownloadTraining(props.row.id, 'csv')">
            <span class="align-middle ml-50">Export CSV</span>
          </b-dropdown-item>

          <b-dropdown-item @click="onDownloadTraining(props.row.id, 'xlsx')">
            <span class="align-middle ml-50">Export XLSX</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div
        v-if="props.column.field === 'course_name'"
        cols="12"
        md="2"
        class="d-flex"
      >
        <b-link @click="editTraining(props.row)">
          {{ props.row.course_name }}
        </b-link>
      </div>
      <!-- Column: Common -->
      <span
        v-else-if="props.column.field === 'role'"
        class="text-capitalize"
      >
        <span>{{ props.row.role }}</span>
      </span>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="(value) => props.pageChanged({ currentPage: value })"
      />
    </template>
  </vue-good-table>
</template>

<script>
import { BButton, VBToggle, BDropdown, BDropdownItem, BNavItemDropdown, BLink } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { makeErrorToast } from "@/libs/utils";
import trainingsService from "@/services/trainingsService";
import { participantRolesDisplay, programTypes } from "@/models";
import { BE_API_URL } from "@/constants/app";
import { useGetAvailability } from "@/views/apps/useGetAvailability";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import { roleAlias } from "@/@aom-core/utils/utils";
import { mapGetters } from 'vuex';
import { userRoles } from '@/models';

export default {
  components: {
    VueGoodTable,
    BButton,
    TablePagination,
    BDropdown,
    BDropdownItem,
    BNavItemDropdown,
    BLink
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isLoading: false,
      columns: [],
      columnFilters: [],
      sort: [],
      rows: [],
      originalRows: [],
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgram']),
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
    isProgramTypeTraining() {
      return this.defaultProgram?.type_id === programTypes.TRAINING;
    },
  },

  
  async created() {
    
    await this.loadItems();
    if (this.$route.query.id) {
      const item = this.rows.find(row => Number(row.id) === Number(this.$route.query.id));
      if (item) {
        this.editTraining(item);
      }
    }
  },
  watch: {
    
    defaultProgram(n){
      this.columns = [
      {
        label: "Training Name",
        field: "course_name",
        filterOptions: {
          enabled: true,
          placeholder: "Search Name",
        },
      },
      {
        label: "Role", 
        field: "role",
        filterOptions: {
          enabled: !this.isProgramTypeTraining,
          placeholder: "All",     
          filterDropdownItems: participantRolesDisplay.filter((r) => r.value !== userRoles.TRAINEE).map(r => { 
            return {...r, text: roleAlias(r.value, this.defaultProgram)};
          }),
        },
        width: "16em",
      },
      {
        label: "In progress",
        field: "started",
        filterOptions: {
          enabled: false
        },
      },
      {
        label: "Completed",
        field: "completed",
        filterOptions: {
          enabled: false
        },
      },
      {
        label: "Eligible",
        field: "eligible",
        filterOptions: {
          enabled: false
        },
      },
      {
        label: "Total",
        field: "total",
        filterOptions: {
          enabled: false
        },
      },
      {
        
        label: "Availability",
        field: "availability",
        width: "16em",
        sortable: false,
      },
      {
        field: "actions",
        sortable: false,
      },
    ];
    this.loadItems();

    }
  },
  methods: {
    async launchProgramTrainingUsers(trainingId) {
      this.$router.push({
        name: "champion-program-training-participants",
        params: {
          trainingId: trainingId
        }
      });
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        if (params[0].field === "role") {
          field = "roles.role_names";
        }
        if (params[0].field === "name") {
          field = "course_name";
        }
        this.sort = [{ field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of ["course_name", "role", "started", "completed"]) {
        if (params.columnFilters[col]) {
          if (col === "role") {
            const role = participantRolesDisplay.find(r => Number(r.value) === Number(params.columnFilters[col]));
            if (role) {
              columnFilters.push({
                field: 'roles.name',
                value: role.text,
              });
            }
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        const response = await trainingsService.getProgramTrainings(
          this.$route.params.id,
          {
            page: this.page,
            perPage: this.perPage,
            columnFilters: this.columnFilters,
            sort: this.sort,
          }
        );
        this.total = response.data.total;
        this.originalRows = response.data.items;
        this.rows = response.data.items.map(item => ({
          id: item.id,
          course_name: item.course_name,
          role:  item.roles.map(r => r.name).join(", "),
          availability: useGetAvailability(item.scheduled_action),
          started: `${item.program_training_users_started_count}`,
          eligible: `${item.eligible_count}`,
          total: `${item.users_count}`,
          completed: `${item.program_training_users_completed_count}`
        }));
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Training list not loaded."));
      } finally {
        this.isLoading = false;
      }
    },
    onDownloadTraining(trainingId, type) {
      const dowloadLink = `${BE_API_URL}/programs/${this.$route.params.id}/trainings/${trainingId}/report/download/${type}`;
      window.document.location = dowloadLink;
    },
    async launchProgramTraining(trainingId) {
      try {
        const response = await trainingsService.launchProgramTraining(
          this.$route.params.id,
          trainingId
        );
        if (response && response.data) {
          window.open(response.data.launch_link);
        }
      } catch (e) {
        console.error(e);
        this.$toast(makeErrorToast("Something went wrong.Unable to get Training Launch Link."));
      } 
    },
    viewProgramTrainingLog(trainingId) {
      this.$router.push({
        name: "champion-program-training-log",
        params: {
          trainingId: trainingId
        }
      });
    },

    editTraining(training) {
      this.$nextTick(() => {
        const originalTraining = this.originalRows.find(row => row.id === training.id);
        this.$emit("edit", originalTraining);
      });
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      total, perPage, page, roleAlias
    };
  }
};
</script>

<style>
.search-date-selector {
  text-align: center !important;
}
</style>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
.nav .dropdown-toggle:not(.active)::after {
  background-image: none !important;
}
</style>
